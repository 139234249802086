import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appScrolltop]',
  standalone: true
})
export class ScrolltopDirective {

  constructor(
    private _element:ElementRef<HTMLElement>,
    private _Renderer2:Renderer2
  ) { }

  @HostListener('window:scroll')Scroll(){
    if(scrollY>=200){
      this._Renderer2.removeClass(this._element.nativeElement,'opacity-0');
      this._Renderer2.addClass(this._element.nativeElement,'opacity-100');
      this._Renderer2.addClass(this._element.nativeElement,'cursor-pointer');
      this._Renderer2.removeClass(this._element.nativeElement,'cursor-auto');
    }
    else{
      this._Renderer2.addClass(this._element.nativeElement,'opacity-0');
      this._Renderer2.removeClass(this._element.nativeElement,'opacity-100');
      this._Renderer2.removeClass(this._element.nativeElement,'cursor-pointer');
      this._Renderer2.addClass(this._element.nativeElement,'cursor-auto');
    }
  }
}
