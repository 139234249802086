import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptionService } from '@app/core/services/encrypt/encryption.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(this.hasToken());
  private userSubject = new BehaviorSubject<any>(this.getUser());
  private PermissionApi = `${environment.url}erp/UserAccount/RouterPermission`;
  constructor(private http: HttpClient, private router: Router , private _enc : EncryptionService) {
    this.initCorporateData();
  }

  private hasToken(): boolean {
    // return !!localStorage.getItem('ecotoken');
    return !!this._enc.decrypt(localStorage.getItem(this._enc.encrypt('ecotoken')))
  }

  private initCorporateData(): void {
    const user = this.getUser();
    if (user) {
      // this.fetchAndStoreCorporateData(user.id);
    }
  }

  getUser(): any {

    return  this._enc.decrypt(localStorage.getItem(this._enc.encrypt('user')??''))
    return JSON.parse(localStorage.getItem('user') || 'null');
  }

  get isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  get user$(): Observable<any> {
    return this.userSubject.asObservable();
  }

  login(data: any, params?: any): Observable<any> {
    let queryParams = new HttpParams();
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          data[key].forEach((value: string, index: number) => {
            formData.append(`${key}[${index}]`, value);
          });
        } else if (data[key] instanceof File) {
          formData.append(key, data[key]);
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    if (params) {
      Object.keys(params).forEach((key) => {
        queryParams = queryParams.append(key, params[key]);
      });
    }
    return this.http.post(`${environment.url}erp/Login/Login`, data, {
      params: queryParams,
    });
  }

  handleLoginResponse(response: any): void {
   
    localStorage.setItem( this._enc.encrypt( 'ecotoken'),  this._enc.encrypt( response.responseData.token));
    localStorage.setItem( this._enc.encrypt( 'ecotokenexpireOn'), this._enc.encrypt(  response.responseData.expiredIn));
    localStorage.setItem(
      this._enc.encrypt(  'userRules'),
      this._enc.encrypt(JSON.stringify(response.responseData.rules))
    );
    // console.log('response' , response)
    localStorage.setItem(this._enc.encrypt('ecoisOneCompany'),this._enc.encrypt(response.responseData.isOneCompany?'true':'false'));
    localStorage.setItem(this._enc.encrypt('ecocompanies'),this._enc.encrypt(JSON.stringify(response.responseData.companies)));
    localStorage.setItem(this._enc.encrypt('eco_current_com_id'),this._enc.encrypt(JSON.stringify(response.responseData.companies.map((obj:any) => obj.id))));

    this.loggedIn.next(true);
  }

 
  logout(): void {
    localStorage.removeItem(this._enc.encrypt('ecotoken'));
    localStorage.removeItem(this._enc.encrypt('ecotokenexpireOn'));
    localStorage.removeItem(this._enc.encrypt('userRules'));
    localStorage.removeItem(this._enc.encrypt('ecoisOneCompany'));
    localStorage.removeItem(this._enc.encrypt('ecocompanies'));
    localStorage.removeItem(this._enc.encrypt('eco_current_com_id'));
    this.loggedIn.next(false);
   
  }

  getUserRules(key: string = 'userRules'): any[] | null {
    const storedValue = this._enc.decrypt(localStorage.getItem(this._enc.encrypt(key)));
    return storedValue ? JSON.parse(storedValue) : null;
  }
  getcompanies(key: string = 'ecocompanies'): any[] | null {
    const storedValue = this._enc.decrypt(localStorage.getItem(this._enc.encrypt(key)));
    return storedValue ? JSON.parse(storedValue) : null;
  }
  getisOneCompany(key: string = 'ecoisOneCompany'):boolean {
    const storedValue =this._enc.decrypt( localStorage.getItem(this._enc.encrypt(key)));
    return storedValue ? storedValue=="true" : false;
  }
 

  getUserAndToken(): Observable<{ token: string | null }> {
    const token = this._enc.decrypt(localStorage.getItem(this._enc.encrypt('tokenEliterz')));
    if (token) {
      return of({ token });
    } else {
      return of({ token: null });
    }
  }


  IsSelectOneCompany():boolean{
    var jsonchoosedcompanies = this._enc.decrypt( localStorage.getItem(this._enc.encrypt('eco_current_com_id')));
    var list = JSON.parse(jsonchoosedcompanies);
    return list.length ==1;
  }

  getPagePermission(route: string) { // Get Permission To Route 
    return this.http.get(this.PermissionApi , {params:{route : route}});
  }


}
