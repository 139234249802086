import { Routes } from '@angular/router';
import { LoginComponent } from './ero-erp/features/auth/components/login/login.component';

export const routes: Routes = [
  { path: '', redirectTo: '/dashboard/view', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@app/ero-erp/eco-routes').then((c) => c.default),
  },
  {
    path:'profile',
    loadChildren:()=>
      import('../app/ero-erp/features/profile/profile-routes')
  },
  { path: '**', redirectTo: '/dashboard/view', pathMatch: 'full' },
];
